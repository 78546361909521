let baseURL = process.env.PUBLIC_URL;
if (process.env.NODE_ENV === 'development') {
    baseURL = '';
}

export default {
    home: {
        name: 'Accueil',
        url: baseURL + '/'
    }
};
