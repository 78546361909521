import React from 'react';

import Item from 'components/Item';

function List(props) {

    return (
        <div className="wrapper-type List">
            {props.lines.length === 0 && (
                <p className={'no-result'}>Aucune fiche horaire de ce type</p>
            )}
            {props.lines.map((line, index) => {
                return (
                    <Item
                        index={index}
                        key={'index-' + index}
                        line={line}
                        setModalData={props.setModalData}
                    />
                );

            })}
        </div>
    );
}

export default List;
