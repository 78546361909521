import React from 'react';
import {useTranslation} from 'react-i18next';

import Logo from 'assets/images/SynchroBus blanc2_1.png';

function Header(props) {
    const {t} = useTranslation();
    return (
        <header className="nq-c-Header">
            <div className="nq-u-wrapper">
                <span className="nq-c-Header-logo">
                    <img src={Logo}/>
                </span>
                <nav className="nq-c-Header-nav">
                    <div className="nq-c-Header-nav-title">
                        <div className="nq-c-Header-nav-title-type">Fiches horaires</div>
                    </div>
                </nav>
            </div>
            <div className="wave">
                <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
                    <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z"/>
                </svg>
            </div>

        </header>
    );
}

export default Header;
