import React, {useEffect, useState} from 'react';
import Popup from 'reactjs-popup';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import Loader from 'components/Loader';

import {pdfjs} from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Popin(props) {
    let modalDataPdf = props.modalDataPdf;
    let [images, setImages] = useState([]);
    let [index, setIndex] = useState(0);


    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const closePopup = () => {
        props.setModalDataPdf({
            open: false,
            hour: false
        });
    }

    useEffect(() => {
        setImages(modalDataPdf.hour.imgs);
    }, [modalDataPdf.hour])

    const prev = () => {
        if (index < 1) {
            return;
        }
        setIndex(index - 1);
    }

    const next = () => {
        if (index >= images.length - 1) {
            return;
        }
        setIndex(index + 1);
    }
    const printPDF = () => {
        window.frames['pdf'].contentWindow.print()
    }

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    }

    return (
        <Popup
            position="top center"
            open={modalDataPdf.open}
            onClose={closePopup}
            className="Popup-PDF"
        >
            <div className="closePopup" onClick={closePopup}>✖</div>

            <img src={modalDataPdf.hour.qrcode} className="qrcode"/>

            {/*<button
                onClick={() => {
                    window.print();
                }}
                className="active printBuutton"
            >
                IMPRIMER
            </button>*/}
            
            <div className={'wrapper-popup-pdf ' + modalDataPdf.hour.type}>
                <Document
                    renderMode="svg"
                    onLoadError={(error) => {
                        console.log('Error ', error);
                    }}
                    file={modalDataPdf.hour.file}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={(
                        <div className="wrapper-global-loader onPdf">
                            <Loader/>
                        </div>
                    )}
                >
                    <Page pageNumber={1}/>
                    {numPages > 1 && (<Page pageNumber={2}/>)}
                </Document>
            </div>
        </Popup>
    );
}

export default Popin;