import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Home from 'pages/Home';
import Loader from 'components/Loader';

import {Configs} from 'redux/actions';


import Pages from 'configs/pages';

let idleTime = false;

function App() {
    const dispatch = useDispatch();
    const configData = useSelector((state) => state.configs);

    const resetPage = () => {
        if (idleTime !== false) {
            clearTimeout(idleTime);
        }
        idleTime = setTimeout(() => {
            document.location = '/';
        }, 1 * 60 * 1000);
    }
    useEffect(() => {
        dispatch(Configs.get());
        let html = document.querySelectorAll('html');

        if (html.length > 0) {
            [
                'mousemove',
                'click',
                'mouseup',
                'mousedown',
                'keydown',
                'keypress',
                'keyup',
                'submit',
                'change',
                'mouseenter',
                'scroll',
                'resize',
                'dblclick'
            ].map((eventName) => {
                html[0].addEventListener(eventName, (event) => {
                    if (idleTime !== false) {
                        clearTimeout(idleTime);
                    }
                    resetPage();
                }, false);
            });
        }

    }, []);

    if (!configData.loaded) {
        return (
            <div className="wrapper-global-loader">
                <Loader/>
            </div>
        );
    }

    return (
        <Router>
            <Switch>
                <Route key={'page1'} exact={true} path={Pages.home.url}>
                    <Home/>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
