import React from 'react';

import Header from 'components/structure/Header';
import Footer from 'components/structure/Footer';

function Wrapper(props) {
    let className = '';
    if (typeof (props.className) !== 'undefined') {
        className = ' ' + props.className;
    }
    let header = true;
    if (typeof (props.displayHeader) !== 'undefined') {
        header = props.displayHeader;
    }
    let footer = true;
    if (typeof (props.displayFooter) !== 'undefined') {
        footer = props.displayFooter;
    }

    return (
        <div className={'main-wrapper' + className}>
            {header && (<Header/>)}
            <div className="page-content">
                {props.children}
            </div>
            {footer && (<Footer/>)}
        </div>
    );
}

export default Wrapper;


