import React from 'react';


function Filters(props) {

    return (
        <div className="nq-c-Content-Filter">
            <div className="nq-c-Content-Filter-Content">
                <div className="nq-c-Content-Filter-Content-buttons">
                    <button className={props.filter === 'regul' ? 'active' : ''} onClick={() => {
                        props.setFilter('regul')
                    }}>
                        Lignes régulières
                    </button>
                    <button className={props.filter === 'scolaire' ? 'active' : ''} onClick={() => {
                        props.setFilter('scolaire')
                    }}>
                        Lignes scolaires
                    </button>

                    <button className={props.filter === 'other' ? 'active' : ''} onClick={() => {
                        props.setFilter('other')
                    }}>
                        Autres lignes
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Filters;
