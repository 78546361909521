import React from 'react';


function Item(props) {
    let line = props.line;

    return (
        <div className={'item-line item' + props.index} onClick={() => {
            props.setModalData({
                open: true,
                line: line
            })
        }}>

            <img className="img-line" src={line.image}/>
            <div className="direction">
                {line.directions.map((direction, index) => {
                    return (<span key={'direction' + index}><span className="arrow">›</span> {direction}</span>);
                })}
            </div>
        </div>
    );
}

export default Item;
/*

        <div className="nq-c-Direction">
            <div className="nq-c-Direction-content">
                <div className="nq-c-Direction-content-line">
                </div>
                <div className="nq-c-Direction-content-detail">
                    <div
                        className="nq-c-Direction-content-detail-location">
                        Direction{line.directions.length > 1 ? 's' : ''}
                        <br/>
                        {line.directions.map((direction, index) => {
                            return (<span key={'direction' + index}>{direction}</span>);
                        })}
                    </div>
                </div>
                <button
                    className="nq-c-Direction-content-action active"
                    onClick={() => {
                        props.setModalData({
                            open: true,
                            line: line
                        })
                    }}
                >
                    Consulter les horaires
                </button>
            </div>
        </div>
 */