import React, {useState} from 'react';
import {useSelector} from 'react-redux';
//import {pdfjs} from 'react-pdf';
import Wrapper from 'components/structure/Wrapper';

import List from 'components/List';
import Filters from 'components/Filters';
import Popin from 'components/Popup';
import PopinPdf from 'components/PopupPdf';
import PopinCover from 'components/PopupCover';

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


function HomePage() {
    const configData = useSelector((state) => state.configs);

    const [modalData, setModalData] = useState({
        open: false,
        line: false
    });

    const [modalDataPdf, setModalDataPdf] = useState({
        open: false,
        hour: false
    });

    const [modalDataCover, setModalDataCover] = useState(true);


    const [filter, setFilter] = useState('regul');

    let lines = configData.data.lines;

    return (
        <Wrapper className="Home">
            <PopinCover
                modalDataCover={modalDataCover}
                setModalDataCover={setModalDataCover}
            />
            <PopinPdf
                modalDataPdf={modalDataPdf}
                setModalDataPdf={setModalDataPdf}
            />

            <Popin
                modalData={modalData}
                setModalData={setModalData}
                setModalDataPdf={setModalDataPdf}
            />

            <section className="nq-c-Content nq-u-wrapper">
                <Filters
                    setFilter={setFilter}
                    filter={filter}
                />
                <div className="nq-c-Content-directions">
                    {(filter === 'all' || filter === 'regul') && (
                        <List
                            title="Lignes régulières"
                            lines={lines.regul}
                            setModalData={setModalData}
                        />
                    )}
                    {(filter === 'all' || filter === 'scolaire') && (
                        <List
                            title="Lignes scolaires"
                            lines={lines.scolaire}
                            setModalData={setModalData}
                        />
                    )}
                    {(filter === 'all' || filter === 'other') && (
                        <List
                            title="Autres lignes"
                            lines={lines.other}
                            setModalData={setModalData}
                        />
                    )}
                </div>

            </section>

        </Wrapper>
    );
}

export default HomePage;
