import {CONFIGS} from 'redux/actionTypes';

import Api from 'services/Api';

export const get = () => {
    return (dispatch) => {
        dispatch({
            type: CONFIGS.FETCHING,
        });
        Api.get(
            {},
            'print/v1/data',
            (error, data) => {
                if (error) {
                    return dispatch({
                        type: CONFIGS.ERROR,
                        payload: {
                            message: error,
                        },
                    });
                }

                return dispatch({
                    type: CONFIGS.GET,
                    payload: data
                });
            },
            'get'
        );
    };
};
