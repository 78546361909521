import {CONFIGS} from 'redux/actionTypes';

const initialState = {
	data: '',
	fetching: false,
	loaded: false,
	error: {
		status: false,
		message: '',
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CONFIGS.GET: {
			return {
				...state,
				data: action.payload,
				loaded: true,
				fetching: false,
				error: {
					status: false,
					message: '',
				},
			};
		}
		case CONFIGS.FETCHING: {
			return {
				...state,
				fetching: true,
				loaded: false,
				error: {
					status: false,
					message: '',
				},
			};
		}
		case CONFIGS.ERROR: {
			const {message} = action.payload;
			return {
				...state,
				fetching: false,
				loaded: true,
				error: {
					status: true,
					message: message,
				},
			};
		}
		default:
			return state;
	}
};
