import React from 'react';
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';


function Popin(props) {


    const openPDF = (hour) => {
        closePopup();
        props.setModalDataPdf({
            open: true,
            hour: hour
        })
    }
    let modalData = props.modalData;
    let line = modalData.line;

    const closePopup = () => {
        props.setModalData({
            open: false,
            line: false
        });
    }
    return (
        <Popup
            position="top center"
            open={modalData.open}
            onClose={closePopup}
        >
            <div className="wrapper-popup">
                <div className="wrapper-popup-header">
                    <img src={line.image}/>
                    <span>{line.title}</span>
                </div>
                <div className="wrapper-popup-buttons">
                    {line !== false && line.hours.map((hour, index) => {
                        return (
                            <button
                                key={'hour' + index}
                                onClick={() => {
                                    openPDF(hour);
                                }}
                                className="active"
                            >
                                {hour.name}
                            </button>
                        );
                    })}
                </div>
            </div>
        </Popup>
    );
}

export default Popin;
