import React from 'react';

import LoaderGif from 'assets/images/Spin-1s-200px.gif';

function Loader(props) {
    return (
        <div className="Loader" role="status">
            <img src={LoaderGif}/>
        </div>
    );
}

export default Loader;
