import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationsFR from 'assets/translates/fr.json';
//import translationsEN from 'assets/translates/en.json';

i18n
	.use(detector)
	.use(initReactI18next)
	.init({
		resources: {
			/*en: {
				translation: translationsEN
			},*/
			fr: {
				translation: translationsFR
			}
		},
		lng: "fr",
		fallbackLng: "fr",
	});

export default i18n;
