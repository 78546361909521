import React from 'react';
import Popup from 'reactjs-popup';
import {useSelector} from 'react-redux';
import moment from 'moment';

import 'moment/locale/fr';


moment.locale('fr');

function Popin(props) {

    const configData = useSelector((state) => state.configs);
    let modalDataCover = props.modalDataCover;

    const closePopup = () => {
        props.setModalDataCover(false);
    }

    return (
        <Popup
            position="top center"
            open={modalDataCover}
            onClose={closePopup}
            className="Popup-PDF"
        >
            <div
                className="nq-c-Home"
                style={{
                    backgroundImage: 'url(' + configData.data.cover + ')'
                }}
                onClick={closePopup}
            >

                <div className="nq-c-HomeBody">
                    <div className="nq-c-HomeAction">
                        <div className="nq-c-HomeAction-title">Découvrir</div>
                        <div className="nq-c-HomeAction-subtitle">Les horaires</div>
                    </div>
                </div>
            </div>
        </Popup>
    );
}

export default Popin;
//Impression et
