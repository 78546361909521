import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';


function Footer(props) {
    let defaultValue = document.body.getAttribute('data-pmr');
    if (defaultValue === null || defaultValue === 'false') {
        defaultValue = false;
    } else {
        defaultValue = true;
    }
    const [pmr, setPmr] = useState(defaultValue);

    const onChangePMR = (event) => {
        const {target} = event;
        document.body.setAttribute('data-pmr', !pmr);
        setPmr(!pmr);
    }

    return (
        <footer className="nq-c-Footer">
            <div className="nq-c-Footer-left">
                <div className="nq-c-FooterWeather case-0"></div>
            </div>
            <div className="nq-c-Footer-right">

                <label htmlFor="toggle_pmr" className="nq-c-Footer-pmr">
                    <input
                        type="checkbox"
                        name="toggle_pmr"
                        id="toggle_pmr"
                        onChange={onChangePMR}
                    />
                    <span className="nq-c-Footer-pmr-icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="29"
                                viewBox="0 0 27 29"
                            >
                                <g fill="#FFF" fillRule="evenodd">
                                    <path
                                        d="M18.5157955,22.5176193 C18.1459909,22.3360734 17.6914081,22.4750336 17.5006268,22.8269136 C16.0414875,25.5243243 13.1573977,27.2008148 9.97157915,27.2008148 C5.30455247,27.2008148 1.5088247,23.5877416 1.5088247,19.147857 C1.5088247,15.7186801 3.79470302,12.66045 7.19578363,11.5375688 C7.58913439,11.407574 7.79758286,10.9985342 7.66097886,10.624231 C7.52437487,10.2499277 7.09568978,10.0515731 6.70116322,10.1815622 C2.69348973,11.5050644 0,15.1091571 0,19.1479718 C0,24.3790946 4.47286902,28.6353712 9.97019231,28.6353712 C13.7234103,28.6353712 17.1223805,26.6607758 18.8405579,23.4837159 C19.0313422,23.1306997 18.8864865,22.6992483 18.5155242,22.5177053 L18.5157955,22.5176193 Z"></path><path
                                    d="M26.79819,22.7319036 C26.6302162,22.3655974 26.1758481,22.1953855 25.783114,22.3531901 L23.1922139,23.3878788 L19.622495,15.7391631 C19.5004427,15.4765421 19.2212844,15.3052222 18.9131297,15.3052222 L10.6327677,15.3052222 L10.6327677,11.9249331 L19.7552109,11.9249331 C20.1829956,11.9249331 20.528614,11.6025721 20.528614,11.2035739 C20.528614,10.8045757 20.1829956,10.4822147 19.7552109,10.4822147 L10.6327677,10.4822147 L10.6327677,6.92158571 C12.3294283,6.58681734 13.6079563,5.18016691 13.6079563,3.49859208 C13.6079563,1.57008156 11.9245982,0 9.85571421,0 C7.78683021,0 6.10470959,1.57008156 6.10470959,3.49974625 C6.10470959,5.18253297 7.3856506,6.59149174 9.08593072,6.92389406 L9.08593072,16.0277356 C9.08593072,16.4267338 9.43154906,16.7490948 9.85933374,16.7490948 L18.4066745,16.7490948 L22.0803388,24.622009 C22.2072264,24.8936469 22.4912015,25.0559498 22.7897041,25.0559498 C22.8912117,25.0559498 22.993932,25.0367876 23.0942331,24.9973379 L26.3920235,23.6808574 C26.7847576,23.5219333 26.9660432,23.0981435 26.7980601,22.7318084 L26.79819,22.7319036 Z M7.65151562,3.49989052 C7.65151562,2.36600044 8.64001748,1.44289151 9.85692072,1.44289151 C11.0726175,1.44289151 12.0623258,2.36487512 12.0623258,3.49989052 C12.0623258,4.63378061 11.073824,5.55688954 9.85692072,5.55688954 C8.64001748,5.55576133 7.65151562,4.63378061 7.65151562,3.49989052 Z"></path>
                                </g>
                            </svg>
                        </span>
                    <span className="nq-c-Footer-pmr-off">OFF</span>
                    <span className="nq-c-Footer-pmr-on">ON</span>
                </label>
            </div>
        </footer>
    );
}

export default Footer;
