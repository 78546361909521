import axios from "axios";

import Constantes from 'configs/constantes';
import Urls from 'configs/urls';

const http = axios.create({
	baseURL: Urls.api
});

class Api {
	cancelTokenSource = false;

	post(object, url, callback) {
		return this.request(object, url, callback, http.post);
	}

	get(object, url, callback) {
		this.cancelTokenSource = axios.CancelToken.source();

		return this.request({
			params: object,
			cancelToken: this.cancelTokenSource.token
		}, url, callback, http.get);
	}

	delete(object, url, callback) {
		return this.request(object, url, callback, http.delete);
	}

	put(object, url, callback) {
		return this.request(object, url, callback, http.put);
	}

	upload(object, url, callback, progress) {
		return this.request(object, url, callback, http.post, {
			'Content-Type': 'multipart/form-data'
		}, progress);

	}

	request(object, url, callback, httpRequest = http.post, headers = {}, progress) {
		httpRequest(
			url,
			object,
			{
				headers
			},
			progress
		)
			.then((response) => {
					if (response.status === 401) {
						if (Constantes.isDev()) {

						} else {
							document.location.href = '/';
						}
						return;
					}
					if (response.status === 200 || response.status === 201 || response.status === 204) {
						if (
							typeof (object.params) !== 'undefined' &&
							typeof (object.params.itemsPerPage) !== 'undefined'
						) {
							callback(false, response.data);
							return;
						}
						return callback(false, response.data);

					}
					callback('Response statut failed', false);
				}
			)
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				if (
					typeof (error) !== 'undefined' &&
					typeof (error.response) !== 'undefined' &&
					typeof (error.response.status) !== 'undefined'
				) {
					if (error.response.status === 401 || error.response.status === 500) {
						if (Constantes.isDev()) {

						} else {
							document.location.href = '/';
						}
						return;
					}
					if (error.response.status >= 400 && error.response.status <= 500) {
						if (typeof (error.response.data['violations']) !== 'undefined') {
							return callback(true, error.response.data['violations']);
						}

						if (typeof error.response.data['hydra:description'] !== 'undefined') {
							return callback(true, error.response.data['hydra:description']);
						}
					}
					if (error.response && error.response.data && error.response.data.message) {
						return callback(true, error.response.data.message);
					}
				}
				callback(true, 'Une erreur s\'est produite');
			});

		return this.cancelTokenSource;
	}
}

export default new Api();
