const generateBaseExport = (prefix) => {
    return {
        GET_ALL: prefix + '-GET_ALL',
        FETCHING_ALL: prefix + '-FETCHING_ALL',
        LOADED_ALL: prefix + '-LOADED_ALL',
        ERROR_ALL: prefix + '-ERROR_ALL',
        RESET_ALL: prefix + '-RESET_ALL',
        GET_ONCE: prefix + '-GET_ONCE',
        FETCHING_ONCE: prefix + '-FETCHING_ONCE',
        LOADED_ONCE: prefix + '-LOADED_ONCE',
        ERROR_ONCE: prefix + '-ERROR_ONCE',
        RESET_ONCE: prefix + '-RESET_ONCE',
        FETCHING_DELETE: prefix + '-FETCHING_DELETE',
        LOADED_DELETE: prefix + '-LOADED_DELETE',
        ERROR_DELETE: prefix + '-ERROR_DELETE',
    };
};

const generateBaseLightExport = (prefix) => {
    return {
        GET: prefix + '-GET',
        FETCHING: prefix + '-FETCHING',
        LOADED: prefix + '-LOADED',
        ERROR: prefix + '-ERROR',
        RESET: prefix + '-RESET',
    };
};


export const CONFIGS = {
    ...generateBaseLightExport('CONFIGS')
};